import React, { createContext, useEffect, useState } from "react";

export const AppContext = createContext()

const ContextProvider = ({ children }) => {
    const [appData, setAppData] = useState("test")
    const [threeJSModule, setThreeJSModule] = useState(null)

    return (
        <AppContext.Provider value={{ appData, threeJSModule,setAppData,setThreeJSModule }}>
                {children}
        </AppContext.Provider>
    )

}

export default ContextProvider;
