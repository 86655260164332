import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import React, { useEffect, useState, useRef } from "react";
import { Box, IconButton, Typography, styled, Drawer, Button, Divider } from '@mui/material';

import AppVersion from "../../package.json";


import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import {triggerAnalytics} from "../modules/GoogleDataLayer";

function FooterComp({ mode, setMode, setPrivacyMode }) {


    return (

        <>
            <Typography
                onClick={() => {
                    triggerAnalytics("privacy-policy")
                    setPrivacyMode(true);
                }}
                sx={{
                    color: "white",
                    cursor:'pointer',
                }}
            >
                Privacy Policy
            </Typography>

            <Typography
                sx={{
                    color: "white",
                }}
            >
                {AppVersion.version}
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    gap: "10px",
                    cursor:'pointer',
                }}
            >
                <Box
                    onClick={() => {
                        window.open("https://www.walaxr.com/products/xr-reality-collection-year-of-the-dragon-unisex-youth", '_blank')

                    }}
                    component="img"
                    src={"./assets/images/walaLogo-horizontal-black.png"}
                    sx={{
                        width: "80px",
                    }}
                />
                <Box
                    onClick={() => {
                        window.open("https://www.3mera.com/", '_blank')

                    }}
                    component='img'
                    src={"./assets/images/3mera-logo.svg"}
                    sx={{
                        width: "70px",
                    }}
                ></Box>

            </Box>

        </>

    );
}

export default FooterComp;
