
import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Typography, styled } from '@mui/material';
import {triggerAnalytics} from "../modules/GoogleDataLayer";

function PermissionComp({ setPrivacyMode }) {

    const reloadHandler = () => {
        window.location.reload();
    }

    return (
        <React.Fragment>
            <Box sx={{
                position: 'fixed',
                top: '0',
                left: "0",
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "40px",
                transition: 'opacity 1s',
                opacity: '1',
                zIndex: '250',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontWeight: 600,
                backgroundColor: "#00000087",
                overflow: "hidden",
                backdropFilter: "blur(2px)"
            }}>


                <Box
                    sx={{
                        width: '80%',
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        color: 'black',
                        borderRadius: "10px",
                        backgroundColor: "white",
                        gap: "20px",
                        padding: "20px",
                        textAlign: "center"
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "17px",
                            fontWeight: "600",

                        }}
                    >Try Again</Typography>

                    <Typography

                    >You need to allow camera and motion permissions to see an AR experience.</Typography>
                    <Button

                        onClick={reloadHandler}
                        variant="contained"
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            backgroundColor: "#70D460",
                            "&:hover": {
                                backgroundColor: "#70D460",
                            },
                        }}
                    >Try Again</Button>
                    <Typography
                        onClick={() => {
                            triggerAnalytics("privacy-policy")
                          setPrivacyMode(true)
                        }}

                        sx={{
                            fontSize: "10px"
                        }}
                    >View Privacy Policy</Typography>
                </Box>
            </Box>
        </React.Fragment >
    );
}

export default PermissionComp;


