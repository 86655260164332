//-------
import { Button, Drawer, Icon, IconButton, SvgIcon, Typography, Container, Box } from '@mui/material';

//--------

// import ContextProvider from './ContextProvider.jsx'
import { useEffect, useState, useRef, useContext } from 'react'
import { AppContext } from './ContextProvider'
import ThreeJSModule from './modules/ThreeJSModule.js'
import LandingComp from './Components/LandingComp.js'
import CaptureButton from './Components/CaptureButton.js';
import AppDrawerComp from './Components/AppDrawerComp.js';
import {initializeGtm} from "./modules/GoogleDataLayer";
import WelcomBannerComp from './Components/WelcomBannerComp.js';
import BackButtonComp from './Components/BackButtonComp.js';
import IframeModule from './Components/IframeModule.js';

function App() {

  // If you move this to bottom and if something went wrong with function specific code, Google Analytics will not work
  initializeGtm();

  const { appData, setThreeJSModule, threeJSModule } = useContext(AppContext)
  const [loading, setLoading] = useState(0)
  const [welcome, setWelcome] = useState(false)
  const [arStarted, setARstarted] = useState(false)
  const [iframeUrl, setIframeUrl] = useState(null)

  const videoRef = useRef()
  const canvasRef = useRef()


  useEffect(() => {
    const _threeJSModule = new ThreeJSModule({
      appData,
      canvas: canvasRef.current,
      setLoading,
      videoDiv: videoRef.current,
      setWelcome,
    })
    setThreeJSModule(_threeJSModule)


    return () => {
      if (threeJSModule) {
        threeJSModule.stop()
      }
    };
  }, [])

  const startHandler = () => {

    if (threeJSModule && loading != "done") {
      setARstarted(true)
      startCam()
    }
  }

  const startCam = () => {
    console.log(videoRef)
    try {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "environment"
          },
        })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          threeJSModule.init()
        })
        .catch(() => {
          console.log("err")
        });
    } catch {
      console.log("err")
    }
  }

  return (
    <>
      {/* <iframe style={{ border: "none" }} width="100%" height="100%" src="https://www.eastvaleca.gov/i-want-to/sign-up-for-enotification" title="description"></iframe> */}

      <Box
        ref={canvasRef}
        component='canvas'
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
      </Box>
      <AppDrawerComp startAR={startHandler} setIframeUrl={setIframeUrl} />

      {welcome && <WelcomBannerComp />}
      {iframeUrl && <IframeModule onClose={() => { setIframeUrl(null) }} iframeUrl={iframeUrl} />}
      {loading != "done" && <LandingComp arStarted={arStarted} setARstarted={setARstarted} loading={loading} startAR={startHandler} />}
      {loading === "done" && <CaptureButton canvas={canvasRef.current} />}
      {loading === "done" && <BackButtonComp />}

      <Box
        component='video'
        ref={videoRef}
        playsInline
        muted
        autoPlay
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: '1px',
          objectFit: "cover",
          zIndex: "-200",
          opacity: 0
        }}
      >
      </Box>
    </>
  )






}

export default App;
