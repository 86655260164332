import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import React, { useEffect, useState, useRef } from "react";
import { Box, IconButton, Typography, styled, Drawer, Button, Divider } from '@mui/material';


import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

import SocialModeComp from './SocialModeComp';
import {triggerAnalytics} from "../modules/GoogleDataLayer";

function AppDrawerComp({ startAR, setIframeUrl }) {

    const [open, setOpen] = useState(false)
    const [mode, setMode] = useState(null)


    return (
        <Box
            sx={{
                zIndex: "5000",
            }}
        >

            {mode && <SocialModeComp mode={mode} setMode={setMode} />}


            <Drawer
                open={open}
                onClose={() => { setOpen(!open) }}
                sx={{
                    zIndex: '5000',
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: "black",
                        alignItems: "flex-start",
                        zIndex: '5000',
                    }
                }}
            >
                <Box
                    sx={{
                        top: "0",
                        left: "0",
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: 'center',
                        gap: "40px",
                        zIndex: '1400',
                        margin: "20px",

                        minWidth: "250px",

                    }}
                >
                    <TextButton onClick={() => {
                        setOpen(false)
                        triggerAnalytics("start_ar")
                        startAR()

                    }} >Launch Experience</TextButton>

                    <TextButton
                        onClick={() => {
                            triggerAnalytics("whats_new_eastvale")
                            window.open("https://www.eastvaleca.gov/i-want-to/sign-up-for-enotification", '_blank')
                            // setIframeUrl("https://www.eastvaleca.gov/i-want-to/sign-up-for-enotification")
                        }}
                    >What's New Eastvale </TextButton>

                    <TextButton
                        onClick={() => {
                            triggerAnalytics("website")
                            window.open("https://www.eastvaleca.gov/", '_blank')
                            // setIframeUrl("https://www.eastvaleca.gov")
                        }}
                    >Website</TextButton>

                </Box>
                <Divider sx={{
                    width: "80%",
                    backgroundColor: "#B92219",
                }} light />
                <Box

                    sx={{
                        top: "0",
                        left: "0",
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: "15px",
                        zIndex: '1400',
                        margin: "20px",

                    }}
                >

                    <CustomIconButton
                        onClick={() => {
                            triggerAnalytics("fb_visit")
                            setMode("facebook")
                        }}
                    >
                        <FacebookIcon />
                    </CustomIconButton>

                    <CustomIconButton
                        onClick={() => {
                            triggerAnalytics("twitter_visit")
                            setMode("twitter")
                        }}
                    >
                        <TwitterIcon />
                    </CustomIconButton>

                    <CustomIconButton
                        onClick={() => {
                            triggerAnalytics("instagram_visit")
                            setMode("instagram")
                        }}
                    >
                        <InstagramIcon />
                    </CustomIconButton>
                    <CustomIconButton
                        onClick={() => {
                            triggerAnalytics("youtube_visit")
                            setMode("youtube")
                        }}
                    >
                        <YouTubeIcon />
                    </CustomIconButton>
                    <CustomIconButton
                        onClick={() => {
                            triggerAnalytics("linkedin_visit")
                            setMode("linkedin")
                        }}
                    >
                        <LinkedInIcon />
                    </CustomIconButton>


                </Box>

            </Drawer>

            <IconButton
                sx={{
                    position: 'absolute',
                    top: "0",
                    left: "0",
                    color: "white",
                    padding: "5px",
                    margin: "20px",
                    zIndex: "3000",
                    backgroundColor: "#333333",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    "&:hover": {
                        background: "#333333",
                    },
                }}
                onClick={() => { setOpen(!open) }}
            >
                <MenuIcon />
            </IconButton>

        </Box>
    );
}

export default AppDrawerComp;



const CustomIconButton = styled(IconButton)((props) => ({
    color: "white",
    padding: "5px",
    backgroundColor: "#B92219",
    "&:hover": {
        background: "#B92219",
        transform: "scale(1.1)",
    },
    "& svg": {
        width: "15px",
        height: "15px",
    },
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
}));


const TextButton = styled(Button)((props) => ({
    color: "white",
    padding: "5px 10px",
    width: "220px",
    fontSize: "1.5rem",
    textAlign: "start",
    justifyContent: "flex-start",
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
}));
