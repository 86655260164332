
import React, { useEffect, useState, useRef } from "react";
import { Box, Grow, Button, Typography, styled } from '@mui/material';

function WelcomBannerComp({ setPrivacyMode }) {

    return (
        <Box
            sx={{
                position: "absolute",
                zIndex: "2000",
                top: "0",
                width: "100%",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grow
                in={true}
            >
                <Box
                    sx={{
                        width: "100%",
                        backgroundColor: "#d92d21",
                        flexDirection: "column",
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: "20px",
                        padding: "20px 10px",
                        position: 'relative',
                    }}
                >
                    <Typography
                        sx={{
                            color: "#ffc107",
                            textAlign: "center",
                            fontFamily: "Outfit",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "normal",
                            maxWidth: "250px",
                        }}
                    >
                        Welcome to the City of Eastvale Lantern Festival 2024!
                    </Typography>
                </Box>
            </Grow>
        </Box>
    );
}

export default WelcomBannerComp;


