import * as THREE from 'three';
import { DeviceOrientationControls } from './DeviceOrientationControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment.js";

class ThreeJSModule {

    constructor(data) {

        this.canvas = data.canvas
        this.setLoading = data.setLoading
        this.videoDiv = data.videoDiv
        this.bgWidth = this.videoDiv.width;
        this.bgHeight = this.videoDiv.height;
        this.videoTexgture = null
        this.gltfLoader = new GLTFLoader();
        this.textureLoader = new THREE.TextureLoader();
        this.audioLoader = new THREE.AudioLoader()
        this.setWelcome = data.setWelcome

        console.log(this.videoDiv)

        this.renderer = null
        this.scene = new THREE.Scene();
        this.camera = null
        this.arScene = new THREE.Group()

        this.scene.add(this.arScene)
        this.animationFrame = null
        this.controls = null



        this.fireTexture = null

        this.action = null
        this.mixer = null
        this.clock = new THREE.Clock();
        this.canvas = data.canvas
        this.mixers = []

        this.listener = new THREE.AudioListener()
        this.bgSound = null
        this.dragonSound = null
        this.fireworkSound = null
        this.actionSound = null
    }


    log(value) {

        console.log(value)
    }
    init() {

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: this.canvas,
            preserveDrawingBuffer: true,
        });

        this.camera = new THREE.PerspectiveCamera(
            85,
            window.innerWidth / window.innerHeight,
            0.01,
            100
        );

        this.camera.position.set(0, 0, 5)
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1;
        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        this.scene.environment = pmremGenerator.fromScene(
            new RoomEnvironment(),
            0.04
        ).texture;

        this.videoTexgture = new THREE.VideoTexture(this.videoDiv);
        this.videoTexgture.encoding = THREE.sRGBEncoding;
        this.scene.background = this.videoTexgture

        const light = new THREE.HemisphereLight(0xffffff, 0xffffff, 2);
        this.scene.add(light);

        this.controls = new DeviceOrientationControls(this.camera);


        window.addEventListener("resize", this.onWindowResize.bind(this));

        THREE.DefaultLoadingManager.onLoad = () => {
            console.log("load")
            // this.ambientSound.play()

            this.setLoading("done")
            this.start();

            setTimeout(() => {
                this.bgSound.play()
                this.dragonSound.play();
                this.fireworkSound.play();
            }, 500)

            setTimeout(() => {
                this.onWindowResize()
            }, 100);

        };


        THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
            const loadingAmount = Math.floor((itemsLoaded / itemsTotal) * 100)
            console.log({ default: loadingAmount });
            this.setLoading(loadingAmount)
        };



        this.load3D()
        this.loadAudio()

    }
    loadAudio() {

        this.camera.add(this.listener)

        this.bgSound = new THREE.Audio(this.listener)
        this.audioLoader.load('./assets/audios/Chinese_Dragon_Music_Only.mp3', (buffer) => {
            this.bgSound.setBuffer(buffer)
            this.bgSound.setLoop(true)
            this.bgSound.setVolume(1)

        })

        this.dragonSound = new THREE.Audio(this.listener)
        this.audioLoader.load('./assets/audios/Chinese_Dragon_Hisses.mp3', (buffer) => {
            this.dragonSound.setBuffer(buffer)
            this.dragonSound.setLoop(true)
            this.dragonSound.setVolume(0.5)

        })

        this.fireworkSound = new THREE.Audio(this.listener)
        this.audioLoader.load('./assets/audios/fireworks.mp3', (buffer) => {
            this.fireworkSound.setBuffer(buffer)
            this.fireworkSound.setLoop(true)
            this.fireworkSound.setVolume(0.3)
            // this.fireworkSound.play();
        })

        this.actionSound = new THREE.Audio(this.listener)
        this.audioLoader.load('./assets/audios/dragon-roar.mp3', (buffer) => {
            this.actionSound.setBuffer(buffer)
            this.actionSound.setLoop(false)
            this.actionSound.setVolume(1)
            // this.actionSound.play();
        })
    }
    onWindowResize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);


        var size = {
            width: this.canvas.width,
            height: this.canvas.height
        }
        var factor = (this.videoDiv.videoWidth / this.videoDiv.videoHeight) / (size.width / size.height);
        this.videoTexgture.offset.x = factor > 1 ? (1 - 1 / factor) / 2 : 0;
        this.videoTexgture.offset.y = factor > 1 ? 0 : (1 - factor) / 2;
        this.videoTexgture.repeat.x = factor > 1 ? 1 / factor : 1;
        this.videoTexgture.repeat.y = factor > 1 ? 1 : factor;

    }

    actionTimeLine() {
        this.actionSound.play();
        setTimeout(() => {
            this.actionSound.play();
        }, 83 * 100)
        setTimeout(() => {
            this.actionSound.play();
            this.setWelcome(true)
        }, 83 * 186)
        setTimeout(() => {
            this.actionSound.play();
        }, 83 * 240)
        setTimeout(() => {
            this.actionSound.play();
        }, 83 * 290)
        setTimeout(() => {
            this.actionSound.play();
        }, 83 * 405)

    }

    load3D() {

        // const geometry = new THREE.BoxGeometry(1, 1, 1);
        // const material = new THREE.MeshBasicMaterial({ color: 0x0000ff });
        // const cube = new THREE.Mesh(geometry, material);
        // this.scene.add(cube);


        this.gltfLoader.load(
            // "https://xr-goods-studio.s3.dualstack.us-east-1.amazonaws.com/city_of_eastvale/FinalDragonAnimation.glb",
            "./assets/models/dragon.glb",
            async (gltf) => {
                this.model = gltf.scene

                gltf.scene.traverse((obj) => {
                    if (obj instanceof THREE.Mesh) {
                        obj.frustumCulled = false
                    }
                })

                this.fireTexture = gltf.scene.getObjectByName("fire").material.map

                this.fireTexture.wrapS = THREE.RepeatWrapping;
                this.fireTexture.wrapT = THREE.RepeatWrapping;



                const mixer = new THREE.AnimationMixer(this.model);

                mixer.addEventListener('loop', () => {
                    this.actionTimeLine()
                }); // properties of e: type, action and direction

                this.actionTimeLine()

                // this.model.scale.set(0.1, 0.1, 0.1)
                // this.model.position.set(, -5, 0)

                console.log({ dragon: gltf })

                this.action = mixer.clipAction(gltf.animations[0]);
                this.action.timeScale = 0.5
                this.mixers.push(mixer)
                // this.action.setLoop(THREE.LoopOnce);
                // this.action.clampWhenFinished = true;
                console.log({ action: this.action })
                this.action.play()

                this.arScene.add(this.model)
                // this.setLoading("done")
            },
            (xhr) => {
                // console.log({ xhr })
                // const loadingAmount = Math.floor((xhr.loaded / xhr.total) * 100)
                // this.setLoading && this.setLoading(loadingAmount)
            },
            (error) => {
                console.log("An error happened when loading 3d models");
            }
        );

        this.gltfLoader.load(
            // "https://xr-goods-studio.s3.dualstack.us-east-1.amazonaws.com/city_of_eastvale/FinalDragonAnimation.glb",
            "./assets/models/cloud.glb",
            async (gltf) => {
                const cloudsGrp = new THREE.Group()

                const clouds = gltf.scene.getObjectByName("cloud_grp")

                console.log({ clouds })

                const cloud_keys = [...Array(20).keys()]
                cloud_keys.forEach(i => {
                    const index = this.randomIndex(clouds.children.length - 1)
                    // console.log({ index })
                    // console.log({ obj: clouds.children })
                    const cloud = clouds.children[index].clone()
                    const position = this.randomPosition()
                    // console.log(position)
                    cloud.position.copy(position)
                    cloud.lookAt(this.camera.position)
                    cloudsGrp.add(cloud)
                })

                this.arScene.add(cloudsGrp)

            },
            (xhr) => {
                // console.log({ xhr })
                // const loadingAmount = Math.floor((xhr.loaded / xhr.total) * 100)
                // this.setLoading && this.setLoading(loadingAmount)
            },
            (error) => {
                console.log("An error happened when loading 3d models");
            }
        );

        this.loadFireWork()

        // const gridHelper = new THREE.GridHelper();
        // this.scene.add(gridHelper);
        // const axesHelper = new THREE.AxesHelper(5);
        // this.scene.add(axesHelper);

    }

    loadFireWork() {

        const blueTexture1 = this.textureLoader.load('./assets/images/firework_blue_1.png')
        const blueTexture2 = this.textureLoader.load('./assets/images/firework_blue_2.png')

        const redTexture1 = this.textureLoader.load('./assets/images/firework_red_1.png')
        const redTexture2 = this.textureLoader.load('./assets/images/firework_red_2.png')


        console.log({ blueTexture1 })
        this.loadFireWorkGlb("./assets/models/firework.glb", blueTexture1, blueTexture2)
        this.loadFireWorkGlb("./assets/models/firework.glb", blueTexture1, redTexture2)
        this.loadFireWorkGlb("./assets/models/firework.glb", redTexture1, blueTexture2)
        this.loadFireWorkGlb("./assets/models/firework.glb", redTexture1, redTexture2)


    }

    loadFireWorkGlb(url, texture1, texture2) {

        this.gltfLoader.load(
            url,
            (gltf) => {
                console.log({ firework: gltf })
                gltf.scene.scale.set(2, 2, 2)
                // gltf.scene.position.set(0, 0, -1)
                const sprite1 = new THREE.Sprite(new THREE.SpriteMaterial({ map: texture1, transparent: true, }))
                const sprite2 = new THREE.Sprite(new THREE.SpriteMaterial({ map: texture2, transparent: true, }))

                sprite1.scale.set(1, 1, 1)
                sprite1.toneMapped = false

                sprite2.scale.set(1, 1, 1)
                sprite2.toneMapped = false

                const parent = gltf.scene.getObjectByName('flower1')
                const parent2 = gltf.scene.getObjectByName('flower2')
                console.log({ parent, parent2 })
                parent.add(sprite1)
                parent2.add(sprite2)

                const position = new THREE.Vector3()
                position.setFromCylindricalCoords(this.getRandomArbitrary(2, 6), Math.random() * Math.PI * 2, this.getRandomArbitrary(-3, 0))
                gltf.scene.position.copy(position)

                console.log({ position })

                const mixer = new THREE.AnimationMixer(gltf.scene)
                const action = mixer.clipAction(gltf.animations[0]);

                setTimeout(() => {
                    action.play()
                }, Math.random() * 2000)

                this.mixers.push(mixer)
                this.arScene.add(gltf.scene)

            },
            (xhr) => {
                // console.log({ xhr })
                // const loadingAmount = Math.floor((xhr.loaded / xhr.total) * 100)
                // this.setLoading && this.setLoading(loadingAmount)
            },
            (error) => {
                console.log("An error happened when loading 3d models");
            }
        );

    }

    animate() {
        this.animationFrame = requestAnimationFrame(this.animate.bind(this));
        this.renderer.render(this.scene, this.camera);
        this.controls.update();
        const delta = this.clock.getDelta();
        // if (this.mixer) this.mixer.update(delta);
        if (this.mixers) {
            this.mixers.forEach((mixer) => {
                mixer.update(delta)  // update animations
            })
        }
        if (this.fireTexture) {
            this.fireTexture.offset.x += 0.01
            this.fireTexture.offset.y += 0.1
        }
        // this.arScene.translateX(0.02)
    }
    stop() {
        cancelAnimationFrame(this.animationFrame)
        this.mindarThree.stop()
    }
    randomIndex(max) {
        // console.log({ max })
        return Math.floor(Math.random() * (max + 1))
    }
    randomPosition() {

        // const position = new THREE.Vector3(this.getRandomArbitrary(1, 5), this.getRandomArbitrary(1, 2), this.getRandomArbitrary(1, 5));

        const position = new THREE.Vector3()
        position.setFromCylindricalCoords(this.getRandomArbitrary(3, 6), Math.random() * Math.PI * 2, this.getRandomArbitrary(-1, 2))
        // setFromSphericalCoords(radius + 0.1, THREE.Math.degToRad(23), THREE.Math.degToRad(23));
        return position
    }
    getRandomArbitrary(min, max) {
        return (Math.random() * (max - min) + min);
    }
    async start() {

        this.onWindowResize()
        this.animate();
    };

}

export default ThreeJSModule