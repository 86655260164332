
import React, { useEffect, useState, useRef } from "react";
import { Box, Grow, Button, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function BackButtonComp({ onClick }) {
    const reloadHandler = () => {
        window.location.reload();
    }
    
    return (
        <CloseIcon
            onClick={reloadHandler}
            sx={{
                position: 'fixed',
                right: "0",
                top: "0",
                margin: "20px",
                width: "30px",
                height: "30px",
                color: "#7F7F7F",
                cursor: "pointer",
                border: "2px solid #7F7F7F",
                padding: "3px",
                borderRadius: "100px",
                zIndex:"3000",
                transitionProperty: "transform",
                transitionDuration: " 0.2s",
                '&:hover': {
                    transform: "scale(1.1)",
                },
            }}

        ></CloseIcon>
    );
}

export default BackButtonComp;


