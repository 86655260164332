
import React, { useEffect, useState, useRef } from "react";
import { Box, Grow, Button, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function IframeModule({ iframeUrl, onClose }) {
    const reloadHandler = () => {
        window.location.reload();
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '0',
                left: "0",
                height: '100%',
                width: '100%',
                zIndex: "6000"
            }}
        >
            <CloseIcon
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: "0",
                    top: "0",
                    margin: "20px",
                    width: "30px",
                    height: "30px",
                    color: "#7F7F7F",
                    cursor: "pointer",
                    border: "2px solid #7F7F7F",
                    padding: "3px",
                    borderRadius: "100px",
                    zIndex: "3000",
                    transitionProperty: "transform",
                    transitionDuration: " 0.2s",
                    '&:hover': {
                        transform: "scale(1.1)",
                    },
                }}

            ></CloseIcon>

            <iframe style={{ border: "none" }} width="100%" height="100%" src={iframeUrl} title="description"></iframe>
        </Box>



    );
}

export default IframeModule;


