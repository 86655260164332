import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import React, { useEffect, useState, useRef } from "react";
import { Box, IconButton, Typography, styled, Drawer, Button, Divider } from '@mui/material';


import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

function SocialModeComp({ mode, setMode }) {


    return (

        <Box
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                bottom: "0",
                left: "0",
                backgroundColor: "#00000091",
                zIndex: '6400',
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter:"blur(2px)"
                // background: "#212121"
            }}
        >
            <Box
                onClick={() => { setMode(null) }}
                sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: "0",
                    left: "0",
                    zIndex: "-1"
                }}
            />

            <Box
                sx={{
                    background: "#212121",
                    padding:"40px 60px",
                    maxWidth:"350px",
                    borderRadius:"20px"
                }}
            >
                {mode === "facebook" && <BoxComp title="Visit Facebook Page" url="https://www.facebook.com/cityofeastvale/" />}
                {mode === "twitter" && <BoxComp title="Visit X Page" url="https://twitter.com/CityEastvaleCA" />}
                {mode === "instagram" && <BoxComp title="Visit Instagram Page" url="https://www.instagram.com/cityofeastvale/" />}
                {mode === "youtube" && <BoxComp title="Visit Youtube Page" url="https://www.youtube.com/user/CityOfEastvale" />}
                {mode === "linkedin" && <BoxComp title="Visit Linkedin Page" url="https://www.linkedin.com/company/cityofeastvale/" />}

            </Box>

        </Box>

    );
}

export default SocialModeComp;



const BoxComp = ({ title, url }) => {
    return (
        <Box
            sx={{
                minWidth: "200px",
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: 'center',
                gap: "50px"
            }}

        >
            <Box
                component="img"
                src={"./assets/images/logo-green.svg"}
                sx={{
                    width: "200px",
                }}
            />
            <SecondaryButton
                sx={{
                    width: "200px"
                }}
                onClick={() => {
                    window.open(url, '_blank')

                }}
            >{title}</SecondaryButton>
        </Box>
    )
}