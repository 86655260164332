
import { Button, styled } from "@mui/material";


const SecondaryButton = styled(Button)((props) => ({
    height: "35px",
    color: "black",
    fontSize: "0.8rem",
    fontFamily: "Roboto",
    fontFamily: "'Inter', sans-serif",
    display: "flex",
    width: "70%",
    maxWidth: "350px",
    borderRadius: "60px",
    padding: "4px 0",
    gap: "10px",
    border: "3px solid white",
    animation: "3s infinite pop",
    backgroundColor:"white",
    fontWeight: 600,
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
    "&:hover": {
      background: "white",
      transform: "scale(1.1)",
    },
  }));


export default SecondaryButton