import TagManager from 'react-gtm-module'

const pushToDataLayer = ( data ) => {
    window.dataLayer.push(data);
    return null; // Since this is a utility, it doesn't render anything
};

const triggerAnalytics = ( clicked_button ) => {
    window.dataLayer.push({
        event: "btn_clicked",
        clicked_button,
    });
    return null; // Since this is a utility, it doesn't render anything
};

const initializeGtm = () => {
    const tagManagerArgs = {
        gtmId: process.env.NODE_ENV === 'development' ?
            process.env.REACT_APP_GTAG_ID_DEV :
            process.env.REACT_APP_GTAG_ID_PROD
    }

    TagManager.initialize(tagManagerArgs)
};

export {pushToDataLayer, triggerAnalytics, initializeGtm};