
import { Button, styled } from "@mui/material";


const PrimaryButton = styled(Button)((props) => ({
    height: "35px",
    color: "white",
    fontSize: "0.8rem",
    fontFamily: "Roboto",
    display: "flex",
    width: "70%",
    fontFamily: "'Inter', sans-serif",
    maxWidth: "350px",
    backgroundColor: "#4B0000",
    // 'border': '3px solid #972113',
    borderRadius: "60px",
    padding: "4px 0",
    gap: "10px",
    animation: "3s infinite pop",
    fontWeight: 600,
    "&:hover": {
        background: "#95783B",
        transform: "scale(1.1)",
    },
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
}));


export default PrimaryButton