import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect, useState, useRef } from "react";
import { Box, Button,Grow, IconButton, LinearProgress, Typography, styled } from '@mui/material';

import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

function CaptureButton({ canvas }) {
    const snapSoundRef = useRef()
    const [dataUrl, setDataUrl] = useState(null)

    const captureHandler = () => {
        console.log(canvas)
        snapSoundRef.current.play()
        const dataUrl = canvas.toDataURL('image/png')
        setDataUrl(dataUrl)

        // console.log(snapSoundRef)
    }

    const saveImage = async () => {
        const fileName = "2024 City of Eastvale Lantern Festival.png"
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = fileName
        link.click()
    }

    const shareImage = async () => {
        const fileName = "Eastvale-Lantern-Festival-2024-Dragon.png"
        const shareFileBlob = await fetch(dataUrl).then(res => res.blob())
        const shareData = {
            files: [new File([shareFileBlob], fileName, {
                type: shareFileBlob.type,
            })],
            title: "Happy Chinese New Year!",
            text: "Check out this awesome Dragon experience from the City of Eastvale Lantern Festival! https://ar.Eastvale.com",
        }

        try {
            await navigator.share(shareData)
            console.log('Data was shared successfully')
        } catch (err) {
            // this.downloadLink.click()
            // window.location.reload(true);
            console.error(err)
            if (err.message !== 'Share canceled') {
                const link = document.createElement('a')
                link.href = dataUrl
                link.download = fileName
                link.click()
            }
        }

    }

    return (
        <>
            <Box
                component='audio'
                ref={snapSoundRef}
                type="audio/mpeg"
                src='./assets/audios/Camera_Snap.mp3'
                sx={{
                    display: "none"
                }}
            >
            </Box>

            {dataUrl &&
                <Grow
                    in={true}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '0',
                            left: "0",
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "40px",
                            transition: 'opacity 1s',
                            opacity: '1',
                            zIndex: '4000',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundColor: "#de2d21",
                            fontWeight: 600,
                            overflow: "hidden",
                        }}
                    >

                        <Box
                            component="img"
                            src={"./assets/images/logo-green.svg"}
                            sx={{
                                width: "120px",
                                position: 'absolute',
                                top: "10px",
                            }}
                        />

                        <IconButton
                            onClick={() => { setDataUrl(null) }}
                            sx={{
                                width: "60px",
                                height: "60px",
                                position: 'absolute',
                                top: "0",
                                right: "0",
                                margin: "5px",
                                color: "white",
                                backgroundColor: "black",
                                border: "solid 3px #ffbf65",
                            }}
                        >
                            <CloseIcon
                                sx={{
                                    width: "35px",
                                    height: "35px"
                                }}
                            />
                        </IconButton>
                        <Box
                            component='video'
                            src="./assets/videos/bg.mp4"
                            playsInline
                            muted
                            autoPlay
                            loop
                            sx={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: '100vw',
                                height: "100vh",
                                objectFit: "cover",
                                zIndex: "-100",
                            }}
                        />
                        <Box
                            component='img'
                            src={dataUrl}
                            sx={{
                                width: "65%",
                                backgroundColor: "white",
                                maxWidth: "300px",
                                top: "0",
                                borderRadius: "7px",
                                border: "solid 3px white",
                                boxShadow: "0 0 7px 2px #ffbf65"
                            }}
                        />

                        <Box
                            sx={{
                                width: "70%",
                                display: "flex",
                                gap: "10px"
                            }}
                        >
                            <SecondaryButton
                                onClick={saveImage}
                                startIcon={<SaveAltIcon />}
                            // onClick={startARHandler}
                            >Save</SecondaryButton>

                            <SecondaryButton
                                onClick={shareImage}
                                startIcon={<ShareIcon />}
                            // onClick={startARHandler}
                            >Share</SecondaryButton>
                        </Box>
                    </Box>
                </Grow>
            }


            <IconButton
                onClick={captureHandler}
                sx={{
                    width: "60px",
                    height: "60px",
                    position: 'absolute',
                    bottom: "50px",
                    zIndex: "1000",
                    color: "#ff9800",
                    border: "solid 3px red",
                    left: "calc(50% - 50px)"
                }}
            >
                <PhotoCameraIcon
                    sx={{
                        width: "35px",
                        height: "35px"
                    }}
                />
            </IconButton>
        </>
    );
}

export default CaptureButton;


